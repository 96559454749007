import React from "react";

function Bag(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210mm"
      height="297mm"
      version="1.1"
      viewBox="0 0 210 297"
      {...props}
    >
      <g>
        <path
          strokeWidth="1.918"
          d="M-47.323 277.29c-43.453-5.776-57.35-8.28-59.872-10.783-4.554-4.522-9.91-36.624-8.46-50.72.623-6.058 3.265-16.082 5.872-22.276 2.607-6.194 5.677-18.799 6.821-28.011 3.351-26.963 13.847-48.742 28.246-58.608 6.748-4.624 25.269-9.46 36.505-9.533 4.137-.026 6.234-.971 6.234-2.81 0-5.66 8.453-12.79 16.308-13.754 11.243-1.38 19.686 4.385 21.311 14.551 1.135 7.095 1.865 7.875 9.142 9.752 14.522 3.747 15.553 4.155 21.336 8.437 4.59 3.398 6.92 3.935 11.51 2.65 12.79-3.582 23.847 4.614 32.9 24.39 13.938 30.445 8.386 86.251-9.64 96.886-3.618 2.135-7.114 6.016-7.769 8.624-1.462 5.826-24.827 36.426-29.05 38.047-5.874 2.254-25.21.628-81.394-6.841zm116.866-89.945c.17-21.24-3.534-36.333-10.663-43.462-5.887-5.888-7.475-4.67-4.775 3.661 2.737 8.445 7.33 37.148 9 56.237 1.335 15.263 1.355 15.31 3.817 8.632 1.362-3.692 2.541-14.973 2.621-25.068zm-65.93 24.901c.71-1.147.488-2.887-.492-3.867-1.457-1.457-84.993-13.23-93.875-13.23-3.591 0-3.292 5.612.374 7.019 2.815 1.08 79.518 11.415 88.502 11.925 2.312.131 4.783-.7 5.492-1.847zm-40.644-44.091c6.607-5.197 6.753-13.682.346-20.09-2.59-2.59-6.513-4.708-8.72-4.708-5.577 0-13.427 8.41-13.427 14.387 0 5.811 7.797 14.386 13.082 14.386 2.015 0 5.94-1.789 8.719-3.975zm28.073-70.836c0-1.055-1.675-1.918-3.723-1.918s-4.257.863-4.91 1.918c-.671 1.088.94 1.919 3.724 1.919 2.7 0 4.909-.864 4.909-1.919z"
        ></path>
      </g>
    </svg>
  );
}

export default Bag;
