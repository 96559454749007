import * as React from "react";

function SlingshotIcon(props: any) {
  return (
    <svg height={512} viewBox="0 0 56 59" width={512} {...props}>
      <path d="M49.65 2.33a3.94 3.94 0 00-4.63-.95c-.76.34-.806.481-4.26 3.71a2.013 2.013 0 00-.08 2.84l8.29 8.72a1.989 1.989 0 002.81.08l2.95-2.75a4.087 4.087 0 00.26-5.66z" />
      <path d="M33.63 14.49c-1.12-4.44-.8-8.09.91-10.33 1.622-2.107 3.85-2.384 6.46-2.03C42.255.953 42.714.513 42.83.43A15.786 15.786 0 0039.01 0a7.439 7.439 0 00-6.05 2.95c-2.17 2.83-2.57 7.29-1.13 12.57a1 1 0 00.24.43l-.72.67a1.95 1.95 0 01-2.67 0l-7.87-7.35C10.264 20.345 13.4 17.1 12.5 18l10.88 10.19c.415.389.65.932.65 1.5V33h11.96v-3.31c0-.568.235-1.111.65-1.5L47.5 18.01l-8.3-8.73zM5.04 8.32a4.062 4.062 0 00-.47 4.74c.409.731.674.876 3.65 3.67a2 2 0 002.81-.08c.05-.1-.138.136 8.32-8.75a2.006 2.006 0 00-.1-2.83l-3.2-3a3.95 3.95 0 00-5.66.26zM24.01 56a3 3 0 003 3h6a3 3 0 003-3v-3h-12z" />
      <path d="M4.69 36.9c.2-.28.4-.55.61-.84 2.84-3.85 6.34-8.59 6.68-17.59a3.9 3.9 0 01-2.04.78 25.323 25.323 0 01-4.62 13.36C5.176 27.629.9 22.742 3.66 15.17a5.97 5.97 0 01-1.29-2.13c-2.86 6.31-1.35 11.06-.12 14.93 1.944 6.1.773 5.706.46 8.26C1.18 38.38.01 40.41.01 43c0 4.116 2.229 6.414 5.99 6.91v-2.02c-2.693-.453-3.99-1.96-3.99-4.89a7.669 7.669 0 011.25-3.98A5.139 5.139 0 006 41.22V40a3 3 0 01.15-.92 2.5 2.5 0 01-1.46-2.18z" />
      <path d="M20 39H9a1 1 0 00-.97.77c-.047.164-.03-.488-.03 10.28.03.531.468.947 1 .95h11a1 1 0 001-1V40a1 1 0 00-1-1zM36.01 35h-12a1 1 0 00-1 1v14a1 1 0 001 1h12a1 1 0 001-1V36a1 1 0 00-1-1z" />
    </svg>
  );
}

export default SlingshotIcon;

