import * as React from 'react';

function SvgSvgAcIconsFurnitureBlack(props: any) {
  return (
    <svg viewBox="0 0 792 612" width="1em" height="1em" {...props}>
      <path d="M489.82 217.48c33.64-21.19 37.02-82.51 37.02-82.51l-71.23-22.61c11.72 38.09 6.09 79.87 2.37 99.01-20.75-21.01-49.53-34.05-81.39-34.05-63.2 0-114.43 51.23-114.43 114.43 0 4.48.29 8.89.79 13.24 1.15 31.73-.45 111.84-43.06 143.7 0 0 128.73 103.07 253.4 16.01-.74.03-1.47.11-2.22.11-27.24 0-49.33-22.09-49.33-49.33s22.09-49.33 49.33-49.33 49.33 22.09 49.33 49.33c0 5.23-.83 10.27-2.34 15 1.23-1.1 2.47-2.21 3.71-3.36 16.32-11.49 29.61-26.97 38.42-45.06.01-.02.03-.04.04-.06.54-.88.91-1.76 1.21-2.64 6.84-14.97 10.67-31.59 10.67-49.13 0-52.78-34.56-97.46-82.29-112.75zm-69.98 59.36c32.42 49.75-2.95 94.56-2.95 94.56 17.81-56.65-7.87-80.64-7.87-80.64-4.45 6.36-8.79 13.4-12.94 20.99-8.23 15.04-19.75 31.67-34.23 49.47 7.91 60.78-7.89 71.59-7.89 71.59 4.48-28.31-6.74-54.37-6.74-54.37s-.75.86-2.16 2.4c-7.4 8.04-33.23 34.7-68 55.02 0 0 29.74-27.74 52.57-63.46-13.71-7.1-41.43 1.71-41.43 1.71 15.88-20.44 53.11-14.99 53.11-14.99 17.26-20.24 30.63-38.94 39.76-55.6 2.71-4.96 5.52-9.73 8.37-14.23-12.9-11.66-82.21-13.81-82.21-13.81 34.7-15.21 90.98.99 90.98.99s.68-.96 1.92-2.54c7.1-9.03 32.95-38.79 59.76-30.73-.01 0-33.15 9.49-40.05 33.64z" />
    </svg>
  );
}

export default SvgSvgAcIconsFurnitureBlack;
