import React from "react";

function Bottoms(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="170.667"
      height="170.667"
      version="1"
      viewBox="0 0 128 128"
      {...props}
    ><g>
      <path
        strokeWidth="1.741"
        d="M58.814 211.627c-23.582-1.937-39.377-4.957-45.545-8.707-5.043-3.067-5.41-3.917-4.661-10.772.447-4.093 1.668-15.668 2.711-25.723 1.829-17.619 9.585-59.464 14.36-77.477l2.309-8.705 66.394-.456 66.395-.456 1.823 6.55c7.702 27.658 15.108 70.9 16.953 98.989l1.073 16.336-6.252 2.612c-7.288 3.045-32.797 7.177-52.252 8.463-16.134 1.067-14.929 2.677-20.886-27.897-2.989-15.342-4.451-19.677-6.641-19.687-2.149-.01-3.5 3.568-5.75 15.221-4.835 25.044-7.667 33.55-11.077 33.268-1.65-.137-10.178-.838-18.954-1.559z"
      ></path>
    </g>
    </svg>
  );
}

export default Bottoms;
