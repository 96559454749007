import React from "react";

function Floor(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      version="1.1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g>
        <path
          strokeWidth="2.982"
          d="M8.2 292.004c-2.929-1.706-5.22-6.572-5.221-11.092-.004-11.265 44.52-153.023 50.063-159.393 4.197-4.823 14.535-5.218 136.32-5.218 121.784 0 132.122.395 136.318 5.218 5.556 6.385 50.068 148.141 50.064 159.436-.002 4.95-2.245 9.296-5.771 11.183-7.879 4.216-354.524 4.088-361.772-.134zm87.226-38.562l1.491-10.403 93.267-.788c73.123-.618 93.519.026 94.432 2.982.64 2.073 1.973 7.46 2.962 11.97 1.029 4.698 3.795 8.201 6.475 8.201 3.806 0 4.302-2.224 2.663-11.928l-2.015-11.928h19.645c15.475 0 19.646-.95 19.646-4.473 0-3.85-9.775-4.474-70.079-4.474h-70.078v-26.839h65.326c63.858 0 65.291-.133 63.767-5.963-1.338-5.116-4.068-5.964-19.198-5.964-11.51 0-18.037-1.296-18.787-3.728-6.134-19.91-6.003-20.129 11.965-20.129 12.038 0 16.36-1.042 15.466-3.728-.924-2.776-16.21-3.934-59.89-4.536l-58.65-.808v-7.392c0-4.583-1.7-7.393-4.473-7.393-2.773 0-4.473 2.81-4.473 7.393v7.392l-58.857.808c-53.31.733-58.952 1.3-59.86 6.027-.866 4.505 1.461 5.22 17.015 5.22 13.038 0 17.7 1.03 16.869 3.727-.632 2.05-1.957 7.418-2.945 11.928-1.735 7.918-2.464 8.2-21.124 8.2-10.63 0-19.327.562-19.327 1.248 0 .686-.806 3.347-1.79 5.914-1.606 4.183 5.044 4.468 64.114 2.75l65.905-1.915V232.6H114.81c-60.304 0-70.079.624-70.079 4.474 0 3.557 4.35 4.473 21.256 4.473h21.255l-1.944 12.16c-1.695 10.597-1.264 12.031 3.346 11.15 3.48-.666 5.802-4.575 6.782-11.416zm12.873-67.254c.98-6.042 2.175-11.364 2.656-11.828.48-.463 36.586-1.82 80.236-3.014l79.363-2.17 2.09 11.143c1.15 6.128 1.224 12.012.164 13.075-1.06 1.062-38.909 2.347-84.11 2.855l-82.182.924 1.783-10.985z"
        ></path>
      </g>
    </svg>
  );
}

export default Floor;
