import * as React from "react";

function FishingRodIcon(props: any) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M467.532 287.089C496.208 258.413 512 220.643 512 180.734c0-39.908-15.792-77.679-44.408-106.295L403.724 9.848l-.018-.017-.041-.043C397.827 3.949 390.064.734 381.807.734s-16.019 3.216-21.861 9.058L9.201 360.752C3.267 366.685 0 374.573 0 382.964c0 8.39 3.267 16.277 9.2 22.211l13.929 13.93c5.869 5.868 13.935 9.199 22.222 9.199.213 0 .428-.002.642-.007 8.511-.177 16.697-3.87 22.467-10.14l203.328-221.166 22.563 22.563a14.946 14.946 0 0010.604 4.393c3.837 0 7.676-1.465 10.604-4.393 5.857-5.856 5.857-15.352 0-21.208l-23.455-23.455 27.611-30.032 24.665 24.665a14.946 14.946 0 0010.604 4.393c3.837 0 7.676-1.465 10.604-4.393 5.857-5.856 5.857-15.352 0-21.208l-25.557-25.557 64.156-69.785 42.136 42.612c23.011 23.011 35.683 53.25 35.683 85.147s-12.672 62.136-35.683 85.147l-.001.001-106.327 106.33c-15.385 15.385-23.859 35.841-23.859 57.598 0 21.759 8.473 42.214 23.858 57.599 15.385 15.385 35.842 23.859 57.6 23.859 21.758 0 42.213-8.474 57.599-23.859 5.856-5.856 5.856-15.352 0-21.208-5.856-5.856-15.352-5.856-21.208 0-9.72 9.721-22.644 15.074-36.39 15.074-13.747 0-26.671-5.353-36.391-15.074-9.72-9.721-15.074-22.644-15.074-36.391-.001-13.746 5.353-26.67 15.073-36.39l106.329-106.33z" />
      <path d="M263.453 250.048L140.967 383.281c16.123 12.146 35.389 18.239 54.666 18.239 23.288 0 46.575-8.864 64.304-26.594 34.241-34.241 35.407-89.213 3.516-124.878z" />
    </svg>
  );
}

export default FishingRodIcon;

