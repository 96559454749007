import React from "react";

function Shoes(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      version="1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g display="inline">
        <path
          strokeWidth="2.019"
          d="M106.905 270.357c-13.847-3.89-37.145-29.224-40.913-44.487-.358-1.448-.288-4.02-1.727-5.8-1.522-1.884-4.598-2.971-6.564-4.293-8.265-5.558-21.72-22.72-27.096-34.559-4.4-9.691-5.029-24.534-1.534-36.198 2.982-9.954 17.732-26.645 28.32-32.047 9.51-4.851 36.047-11.89 44.797-11.88 3.88.003 19.702-6.863 41.862-18.168 39.936-20.373 47.754-22.14 60.31-13.632 6.616 4.482 8.903 9.246 14.05 29.27 1.795 6.985 3.072 8.58 6.875 8.58 2.569 0 7.67 2.043 11.335 4.541 5.964 4.064 7.895 8.15 18.36 38.859 6.432 18.874 11.742 36.8 11.801 39.836.064 3.333-2.092 7.892-5.444 11.51-6.198 6.688-98.236 58.405-115.567 64.938-13.37 5.04-28.575 6.42-38.865 3.53zm28.175-75.632c.996-2.99-13.07-18.606-19.974-22.176-5.886-3.044-11.727-2.66-11.727.77 0 .94 3.09 3.183 6.868 4.984 3.778 1.801 9.85 6.986 13.494 11.52 6.283 7.818 9.853 9.36 11.339 4.902zm20.783-10.512c0-9.122-24.424-29.379-29.27-24.275-1.368 1.44-.717 2.871 2.019 4.44 2.22 1.272 8.579 7.037 14.13 12.81 11.015 11.456 13.121 12.584 13.121 7.025zm53.984-37.805c13.592-7.584 25.222-15.391 25.844-17.351.622-1.96-.384-5.876-2.236-8.702-5.58-8.515-14.237-6.962-40.006 7.176-12.346 6.773-24.013 13.705-25.927 15.406-5.546 4.925-6.379 10.468-2.257 15.022 6.087 6.727 16.79 3.953 44.582-11.551zm-124.043 1.86c4.246-4.246-18.328-27.658-25.62-26.571-5.882.876-6.133 4.973-.394 6.413 2.768.695 8.94 5.799 13.713 11.342 8.787 10.203 10.026 11.091 12.3 8.816zm21.612-13.298c0-7.038-23.458-27.768-28.144-24.872-3.797 2.347-2.268 6.666 2.81 7.94 2.72.684 8.805 5.721 13.52 11.195 7.962 9.243 11.814 11.114 11.814 5.737zm69.154-31.543c14.164-6.701 26.341-13.718 27.06-15.591.719-1.874-.133-5.79-1.892-8.704-5.674-9.393-14.866-8.059-43.856 6.369-20.82 10.361-25.579 13.565-27.211 18.32-1.707 4.974-1.3 6.312 2.981 9.778 6.868 5.56 12.477 4.231 42.918-10.172z"
        ></path>
      </g>
    </svg>
  );
}

export default Shoes;
