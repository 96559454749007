import React from "react";

function Villager(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      version="1.1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g>
        <path
          strokeWidth="7.5"
          d="M197.212 490.25c-117.63-36.11-158.487-151.475-81.065-228.897 14.37-14.37 42.26-55.857 61.979-92.195 19.719-36.338 39.086-65.89 43.039-65.673 3.952.218 14.297 21.742 22.989 47.83l15.802 47.435 36.897 2.188 36.897 2.187 17.54-49.03c9.646-26.967 20.512-50.869 24.147-53.115 3.635-2.247 22.312 27.27 41.504 65.593 19.193 38.322 47.604 83.534 63.136 100.471 67.623 73.74 30.717 185.334-72.628 219.604-46.773 15.51-164.856 17.533-210.237 3.602z"
        ></path>
      </g>
    </svg>
  );
}

export default Villager;
