import * as React from "react";

function AxeIcon(props: any) {
  return (
    <svg viewBox="0 0 512 512" width="1em" height="1em" {...props}>
      <path d="M401.476 68.07c-13.164-13.163-33.802-15.857-50.411-8.617a311.487 311.487 0 0062.407 46.425c1.819-13.457-1.676-27.481-11.996-37.808zM324.931 76.439l-29.594-29.594c-5.863-5.863-15.362-5.863-21.225 0L167.974 152.97c-5.863 5.863-5.863 15.362 0 21.225l29.594 29.609c31.907 31.898 56.732 69.429 73.821 110.507 67.96-27.836 136.387-97.029 163.968-164.097-41.043-17.087-78.55-41.899-110.426-73.775z" />
      <path d="M499.934 170.091c-12.408-2.483-24.521-5.845-36.469-9.632-30.708 75.305-105.648 151.139-181.813 182.031 3.765 11.899 7.109 23.963 9.581 36.317 1.781 8.996 11.101 14.126 19.466 11.286 84.327-28.099 172.423-116.194 200.522-200.536 2.881-8.647-2.536-17.735-11.287-19.466zM178.422 227.436L13.17 392.687c-17.56 17.561-17.56 46.129 0 63.689 17.546 17.574 46.114 17.56 63.675 0L232.09 301.131c-14.083-26.904-32.103-51.687-53.668-73.695z" />
    </svg>
  );
}

export default AxeIcon;

