import React from "react";

function Palette(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      version="1.1"
      viewBox="0 0 96 96"
      {...props}
    >
      <g strokeOpacity="1" display="inline">
        <path
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="1"
          d="M70.027 26.08c1.352 1.234 1.182 3.789.046 5.321-2.952 4.77-12.023 6.932-9.684 10.192 4.373 3.96 9.069-1.41 14.799-1.977 4.147-.41 7.657 2.913 8.346 4.305C94.72 65.205 58.16 78.92 45.463 77.964 14.712 76.825 10.373 57.122 9.81 47.316c.63-24.02 24.39-28.78 37.418-29.43 6.738.413 13.096-.392 22.798 8.194z"
        ></path>
        <ellipse
          cx="45.174"
          cy="33.818"
          fill="none"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth="0.656"
          rx="9.222"
          ry="6.509"
        ></ellipse>
        <path
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="1"
          d="M36.364 68.756c2.456.83 3.979.12 4.783-1.771 3.415-.893 3.313-1.926 3.93-2.95 1.113-10.945-18.64-.173-8.713 4.72z"
        ></path>
        <path
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="1"
          d="M49.705 61.95c-1.542 1.967-3.286 4.863.89 8.299 2.487 3.355 3.232-2.508 7.14-3.478 3.586-1.811-3.18-11.64-8.03-4.82z"
        ></path>
        <path
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="1"
          d="M62.004 59.441c-.544 3.274-1.489 6.572 2.225 7.447 1.055-.227 6.325-2.06 6.685-5.829.772-3.692-2.15-3.501-4.593-4.017-1.925-.438-3.908.288-4.317 2.4z"
        ></path>
        <path
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth="0.035"
          d="M47.255 18.359c-3.496.085-6.974.595-10.399 1.283-6.384 1.333-12.714 3.752-17.73 8.023a24.604 24.604 0 00-8.04 13.139c-.73 3.097-1.071 6.328-.56 9.489.763 5.924 2.958 11.786 6.956 16.296 4.094 4.7 9.845 7.684 15.82 9.228 4.333 1.129 8.811 1.68 13.284 1.743 5.216-.045 10.33-1.385 15.215-3.127 6.352-2.335 12.546-5.507 17.437-10.262 2.897-2.851 5.292-6.468 5.842-10.567.062-1.74.192-3.526-.301-5.22-.45-1.668-1.031-3.34-1.99-4.788-1.37-1.694-3.317-2.934-5.443-3.42-2.196-.436-4.403.254-6.417 1.088-1.954.737-3.828 1.783-5.911 2.1-1.24.162-2.553.089-3.651-.57-.87-.42-1.794-1.147-1.747-2.214-.091-1.073.795-1.907 1.553-2.55 2.262-1.818 4.932-3.095 7.04-5.106 1.187-1.161 2.367-2.605 2.313-4.362.026-.924-.329-1.873-1.1-2.421-3.605-3.113-7.728-5.851-12.44-6.891-3.185-.72-6.478-.835-9.73-.891zm-2.026 8.736c2.978-.062 6.128.976 8.106 3.292.878 1.005 1.363 2.328 1.283 3.661-.037 2.13-1.598 3.928-3.361 4.958-2.006 1.198-4.366 1.643-6.677 1.551-2.597-.094-5.24-.981-7.125-2.816-1.473-1.41-2.208-3.658-1.46-5.607.84-2.29 3.075-3.784 5.324-4.49 1.258-.437 2.58-.584 3.91-.55zM65.22 56.506c1.424.144 2.85.354 4.23.734.996.314 1.926 1.143 1.962 2.24.143 1.656-.33 3.41-1.483 4.649-1.38 1.474-3.247 2.424-5.137 3.05-.754.32-1.557-.144-2.206-.516-1.146-.705-1.613-2.116-1.493-3.407.096-1.626.236-3.303.822-4.842.58-1.249 1.96-1.94 3.305-1.908zm-23.628 2.368c1.47-.037 2.997.756 3.56 2.16.413.997.423 2.121.335 3.176-.517 1.13-1.334 2.17-2.518 2.637-.573.318-1.42.241-1.729.926-.63 1.036-1.784 1.83-3.034 1.705-1.72.095-3.41-.86-4.402-2.213-.888-1.194-.83-2.867-.02-4.092 1.4-2.204 3.824-3.623 6.343-4.157a7.08 7.08 0 011.465-.142zm11.963.302c2.138.002 3.952 1.59 4.923 3.389.626 1.142.956 2.582.325 3.79-.363.65-1.115.891-1.77 1.147-1.762.868-2.638 2.765-4.151 3.928-.688.494-1.65.233-2.164-.392-1.053-1.086-2.31-2.097-2.836-3.564-.681-1.665-.12-3.546.874-4.955.993-1.392 2.146-2.955 3.94-3.274.276-.05.576-.07.86-.069z"
        ></path>
      </g>
    </svg>
  );
}

export default Palette;
