import React from "react";

function Umbrella(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      version="1.1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g>
        <path
          strokeWidth="4.85"
          d="M114.034 527.416c-21.766-25.395-43.21-69.88-51.134-106.08-3.795-17.332-5.053-47.377-3.453-82.446 2.826-61.95 12.686-96.816 40.39-142.823l16.787-27.88-10.213-10.872c-22.856-24.329 2.706-49.155 27.057-26.278l11.274 10.591 20.987-14.186c44.673-30.196 100.584-45.971 164.058-46.29 71.996-.36 128.363 18.175 173.38 57.014 32.327 27.89 25.732 54.96-10.31 42.32-20.756-7.28-38.209-2.772-59.062 15.253-25.093 21.692-28.164 50.537-9.34 87.74 11.132 22.004 12.396 27.895 7.301 34.034-7.747 9.335-14.208 9.332-32.947-.016-12.343-6.158-18.757-6.473-37.2-1.829-12.259 3.087-23.61 7.75-25.225 10.365-4.172 6.75 66.45 76.5 77.457 76.5 5.02 0 24.88 15.278 44.137 33.95 19.256 18.671 37.643 33.948 40.859 33.948 12.632 0 10.893-15.123-3.643-31.68-17.76-20.226-18.412-25.803-5.012-42.838 12.814-16.29 28.649-16.316 47.948-.076 20.548 17.29 29.297 35.945 29.297 62.47 0 57.273-52.313 90.624-103.375 65.905-25.109-12.155-85.767-73.09-85.767-86.16 0-4.99-17.192-26.163-38.204-47.051l-38.203-37.98-8.732 22.864c-7.47 19.558-7.835 25.066-2.529 38.093 10.636 26.11 11.226 34.907 2.81 41.892-6.94 5.76-11.003 5.055-29.674-5.145-28.166-15.386-68.442-16.396-84.505-2.118-17.665 15.7-29.902 47.667-24.095 62.941 2.69 7.074 4.89 18.176 4.89 24.671 0 9.818-2.389 11.809-14.171 11.809-10.668 0-18.54-5.096-31.838-20.612z"
        ></path>
      </g>
    </svg>
  );
}

export default Umbrella;
