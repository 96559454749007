import React from "react";

function Goods(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      version="1.1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g>
        <path
          strokeWidth="4.688"
          d="M173.932 482.594c-19.064-4.992-41.74-14.476-50.39-21.074l-15.73-11.997V165.697l10.771-10.119c47.39-44.52 242.66-48.106 311.495-5.721 12.519 7.708 15.234 12.317 15.234 25.855 0 16.28.238 16.475 20.101 16.475 101.136 0 128.776 145.778 35.064 184.933-9.55 3.99-25.869 7.255-36.264 7.255h-18.9v30.085c0 38.751-2.075 43.391-24.513 54.838-47.418 24.191-178.34 31.242-246.868 13.296zm318.823-166.792c26.056-27.735-1.52-78.28-37.564-68.854-13.79 3.606-15.333 79.361-1.675 82.186 14.515 3.002 28.29-1.678 39.239-13.332zM329.963 210.77c38.175-5.518 73.162-19.824 73.162-29.916 0-35.277-207.03-42.991-244.469-9.109-30.306 27.427 79.107 52.353 171.307 39.025z"
        ></path>
      </g>
    </svg>
  );
}

export default Goods;
