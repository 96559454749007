import React from "react";

function Clothes(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210mm"
      height="297mm"
      version="1.1"
      viewBox="0 0 210 297"
      {...props}
    >
      <g display="inline">
        <path
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="0.265"
          d="M79.135 153.42c-3.543 1.049-7.046 2.528-10.423 5.35-.3.34-.204.815.026 1.336 1.446 2.058 2.891 4.426 4.34 6.173.316.431.905.34 1.47-.058l1.31-1.228-1.674 6.237c-.103.74-.56 1.503.22 2.474.692.65 1.091 1.28 3.228 2.024 1.036.297 2.277.546 3.67.756 1.604.173 3.643.203 6.582-.06 1.31-.174 2.459-.428 3.413-.782.789-.237 1.19-.47 1.64-.625.459-.25.883-.484 1.344-1.262.228-.576.535-1.19.314-2.102l-1.83-6.793 1.627 1.613c.358.306 1.16.387 1.47-.025 2.148-2.49 2.785-3.948 4.178-5.922.53-.988.228-1.155.075-1.47-3.692-3.252-6.945-4.544-10.123-5.505-.36.766-.711 4.108-5.239 4.17-2.11-.1-4.531-.01-5.618-4.302z"
        ></path>
        <g>
          <ellipse
            cx="84.137"
            cy="166.195"
            fill="none"
            strokeWidth="0.265"
            rx="0.896"
            ry="0.991"
          ></ellipse>
          <ellipse
            cx="84.391"
            cy="161.557"
            fill="none"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.18"
            rx="1.644"
            ry="1.669"
          ></ellipse>
          <ellipse
            cx="84.391"
            cy="166.501"
            fill="none"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.18"
            rx="1.644"
            ry="1.669"
          ></ellipse>
          <path
            fill="none"
            stroke="#000"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.021"
            d="M78.012 153.832c-3.343 1.079-6.55 2.683-9.234 4.97-.288.25-.173.697-.088 1.016.809 1.38 1.752 2.677 2.64 4.006.618.862 1.201 1.76 1.927 2.537.356.278.877.083 1.193-.174.527-.41.987-.896 1.488-1.337-.603 2.409-1.323 4.788-1.868 7.211-.176.623.055 1.29.512 1.732.938 1.023 2.212 1.68 3.55 2.008 1.785.444 3.612.78 5.459.756 2.076.004 4.175-.066 6.206-.531 1.156-.29 2.295-.677 3.366-1.202.788-.455 1.28-1.316 1.42-2.199.088-.946-.311-1.841-.512-2.748-.463-1.716-.935-3.43-1.38-5.15.667.605 1.257 1.304 1.986 1.839.39.155.927.167 1.185-.226 1.593-1.768 2.788-3.847 4.096-5.817.246-.4.497-.996.11-1.392-1.076-1.034-2.306-1.897-3.564-2.693-1.972-1.224-4.134-2.122-6.358-2.77-.252.064-.245.419-.34.618-.344 1.176-1.038 2.342-2.165 2.902-.722.384-1.546.53-2.356.496-1.328 0-2.746.109-3.928-.614-1.155-.676-1.775-1.956-2.149-3.188-.003-.386-.302-.424-.585-.251l-.611.2zm6.4 6.084c.651-.077 1.208.413 1.495.954.142.392.098.82.053 1.223-.237.683-.932 1.174-1.66 1.122-.984.011-1.74-1.05-1.524-1.984.138-.746.84-1.405 1.635-1.315zm.005 4.918c.885-.014 1.696.83 1.595 1.72.047.914-.846 1.7-1.744 1.611-.888-.04-1.623-.91-1.505-1.794 0-.861.82-1.565 1.654-1.537z"
          ></path>
          <path
            fill="none"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.021"
            d="M78.012 153.832c-3.343 1.079-6.55 2.683-9.234 4.97-.288.25-.173.697-.088 1.016.809 1.38 1.752 2.677 2.64 4.006.618.862 1.201 1.76 1.927 2.537.356.278.877.083 1.193-.174.527-.41.987-.896 1.488-1.337-.603 2.409-1.323 4.788-1.868 7.211-.176.623.055 1.29.512 1.732.938 1.023 2.212 1.68 3.55 2.008 1.785.444 3.612.78 5.459.756 2.076.004 4.175-.066 6.206-.531 1.156-.29 2.295-.677 3.366-1.202.788-.455 1.28-1.316 1.42-2.199.088-.946-.311-1.841-.512-2.748-.463-1.716-.935-3.43-1.38-5.15.667.605 1.257 1.304 1.986 1.839.39.155.927.167 1.185-.226 1.593-1.768 2.788-3.847 4.096-5.817.246-.4.497-.996.11-1.392-1.076-1.034-2.306-1.897-3.564-2.693-1.972-1.224-4.134-2.122-6.358-2.77-.252.064-.245.419-.34.618-.344 1.176-1.038 2.342-2.165 2.902-.722.384-1.546.53-2.356.496-1.328 0-2.746.109-3.928-.614-1.155-.676-1.775-1.956-2.149-3.188-.003-.386-.302-.424-.585-.251l-.611.2zm6.4 6.084c.651-.077 1.208.413 1.495.954.142.392.098.82.053 1.223-.237.683-.932 1.174-1.66 1.122-.984.011-1.74-1.05-1.524-1.984.138-.746.84-1.405 1.635-1.315zm.005 4.918c.885-.014 1.696.83 1.595 1.72.047.914-.846 1.7-1.744 1.611-.888-.04-1.623-.91-1.505-1.794 0-.861.82-1.565 1.654-1.537z"
          ></path>
          <path
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="0.01"
            d="M78.993 153.481c-1.277.399-2.55.82-3.779 1.353-2.318.988-4.51 2.292-6.437 3.92-.284.236-.238.654-.143.97.137.45.47.8.714 1.193 1.226 1.777 2.363 3.623 3.738 5.292.155.231.445.344.715.278.414-.073.752-.342 1.043-.63.359-.333.715-.668 1.073-1.003-.593 2.28-1.234 4.55-1.8 6.837-.11.44-.204.915-.034 1.354.177.547.65.907 1.053 1.285.734.664 1.665 1.058 2.596 1.36 1.122.323 2.273.542 3.428.706 1.286.188 2.59.16 3.885.147 1.106-.047 2.215-.094 3.311-.248 1.451-.203 2.86-.635 4.21-1.194.707-.249 1.334-.746 1.672-1.421.298-.619.499-1.336.307-2.017-.607-2.315-1.247-4.621-1.855-6.936.617.582 1.191 1.213 1.834 1.768.327.198.776.246 1.116.053.332-.263.57-.624.846-.941 1.275-1.568 2.292-3.322 3.414-4.996.22-.339.442-.745.363-1.163-.086-.289-.304-.504-.536-.683a24.442 24.442 0 00-6.848-4.187 33.445 33.445 0 00-2.77-.961c-.176-.031-.174.203-.227.314-.265.864-.63 1.728-1.273 2.381a3.6 3.6 0 01-1.299.918 6.005 6.005 0 01-2.538.49c-.967-.004-1.958-.045-2.875-.397-.842-.325-1.526-.98-1.97-1.76-.373-.628-.603-1.316-.813-2.016-.028-.042-.067-.077-.12-.066zm5.349 6.403c.478-.019.954.182 1.265.548.455.504.53 1.302.218 1.898-.27.467-.753.828-1.303.862-.742.082-1.482-.424-1.7-1.134a2.035 2.035 0 01-.03-.943c.18-.682.83-1.246 1.55-1.23zm.05 4.939c.81-.017 1.546.662 1.615 1.465a1.68 1.68 0 01-.921 1.696 1.67 1.67 0 01-1.133.106c-.657-.173-1.154-.789-1.201-1.464a1.667 1.667 0 011.067-1.708c.182-.07.379-.093.573-.095z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Clothes;
