import * as React from "react";

function ButterflyNetIcon(props: any) {
  return (
    <svg height={512} viewBox="0 0 511.23 511.23" width={512} {...props}>
      <path d="M469.449 145.544c-26.783-52.031-66.976-97.055-119.461-133.823L333.406.106 0 488.575l33.038 22.55 175.353-256.907c73.365 16.04 142.505 59.602 216.458 136.141 14.117 14.611 34.686 19.191 53.677 11.955 19.05-7.259 31.391-24.424 32.207-44.796.37-9.227.535-18.637.49-27.968-.325-68.879-14.38-130.788-41.774-184.006zm-67.748-31.778a304.76 304.76 0 0124.843 36.336l-28.29 41.447a790.474 790.474 0 00-13.15-10.781 870.384 870.384 0 00-19.019-14.822zm-41.241 98.512a732.723 732.723 0 0115.122 12.488l-30.976 45.383c-11.768-7.703-23.547-14.674-35.369-20.914l34.266-50.203a807.177 807.177 0 0116.957 13.246zm45.053 39.59c10.764 10.479 20.298 20.705 28.556 30.627l-25.288 37.049c-10.565-9.333-21.09-18.062-31.598-26.171zm22.873-33.511l18.265-26.759c6.302 15.959 11.381 32.659 15.251 50.12l-4.493 6.583c-8.674-9.819-18.341-19.802-29.023-29.944zM343.336 56.51c10.97 8.532 21.217 17.449 30.756 26.753l-40.402 59.193a983.063 983.063 0 00-33.717-22.414zm-65.939 96.608a965.03 965.03 0 0133.728 22.398l-38.693 56.689c-13.202-5.272-26.5-9.706-39.941-13.296zm186.886 211.818c-1.841.701-6.564 1.874-10.667-2.371a866.76 866.76 0 00-15.259-15.397l20.251-29.67c6.015 10.784 10.177 21.056 12.408 30.73-.069 2.573-.149 5.14-.251 7.689-.241 6.002-4.614 8.306-6.482 9.019z" />
    </svg>
  );
}

export default ButterflyNetIcon;

