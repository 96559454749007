import React from "react";

function Tool(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="170.667"
      height="170.667"
      version="1"
      viewBox="0 0 128 128"
      {...props}
    >
      <path
        d="M790 1198c-12-13-39-47-62-75-45-59-46-77-13-185l18-57-64-73c-34-40-68-79-75-87-10-11-20-9-63 17-66 41-117 62-151 62-52 0-180-160-221-277-23-67-29-194-11-259 27-95 100-151 209-161 135-12 272 46 394 168 43 43 85 92 94 108 14 28 14 34 0 61-8 16-42 58-75 94l-60 64 72 85 73 86h102l101 1 66 72c53 57 66 79 66 104 0 34 2 33-220 196-121 88-143 95-180 56zm168-223c39-27 72-55 72-61 0-27-49-45-103-38-77 10-104 29-117 85-10 40-9 51 5 73 20 30 8 35 143-59z"
        transform="matrix(.1 0 0 -.1 0 128)"
      ></path>
    </svg>
  );
}

export default Tool;
