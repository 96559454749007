import React from "react";

function Accessory(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210mm"
      height="297mm"
      version="1.1"
      viewBox="0 0 210 297"
      {...props}
    >
      <g display="inline">
        <path
          strokeWidth="1"
          d="M537.686 493.775a25.38 25.506 0 00-25.286 25.506 25.38 25.506 0 0025.38 25.506 25.38 25.506 0 0025.38-25.506 25.38 25.506 0 00-25.38-25.506 25.38 25.506 0 00-.094 0zm-.358 11.133a14.442 14.442 0 01.635.012 14.442 14.442 0 0113.816 15.04 14.442 14.442 0 01-15.039 13.82 14.442 14.442 0 01-13.818-15.042 14.442 14.442 0 0114.406-13.83z"
          transform="scale(.26458)"
        ></path>
        <path
          strokeWidth="1"
          d="M592.232 493.775a25.38 25.506 0 00-25.285 25.506 25.38 25.506 0 0025.381 25.506 25.38 25.506 0 0025.381-25.506 25.38 25.506 0 00-25.38-25.506 25.38 25.506 0 00-.097 0zm1.168 11.11a14.442 14.442 0 01.635.013 14.442 14.442 0 0113.819 15.04 14.442 14.442 0 01-15.042 13.818 14.442 14.442 0 01-13.818-15.041 14.442 14.442 0 0114.406-13.83z"
          transform="scale(.26458)"
        ></path>
        <path
          strokeWidth="0.309"
          d="M148.034 135.94H150.90699999999998V138.813H148.034z"
        ></path>
        <path
          strokeWidth="0.033"
          d="M140.919 119.335c-.858 1.308-1.63 2.673-2.366 4.053-.94 1.798-1.584 3.732-2.128 5.682-.56 2.105-.817 6.017-.866 8.184.731-.968 2.044-4.69 3.157-5.46.142-.305.098-.67.18-.99.271-1.506.773-2.964 1.318-4.392.347-.866.776-1.695 1.15-2.55.208.541.413 1.084.65 1.613.147.129.33-.066.487-.092.644-.272 1.314-.494 1.93-.828.114-.15-.062-.34-.086-.5-.556-1.599-1.291-3.125-1.96-4.678-.154-.14-.406-.031-.599-.067-.286.017-.592-.043-.867.025z"
        ></path>
        <path
          strokeWidth="0.033"
          d="M158.078 119.335c.858 1.308 1.63 2.673 2.365 4.053.94 1.798 1.585 3.732 2.129 5.682.56 2.105.817 6.017.865 8.184-.73-.968-2.043-4.69-3.156-5.46-.143-.305-.099-.67-.18-.99-.271-1.506-.774-2.964-1.318-4.392-.348-.866-.776-1.695-1.151-2.55-.207.541-.413 1.084-.65 1.613-.146.129-.33-.066-.486-.092-.644-.272-1.314-.494-1.93-.828-.114-.15.061-.34.086-.5.556-1.599 1.291-3.125 1.96-4.678.154-.14.405-.031.598-.067.286.017.593-.043.868.025z"
        ></path>
        <ellipse
          cx="81.812"
          cy="154.519"
          fill="none"
          strokeWidth="0.265"
          rx="8.362"
          ry="8.129"
        ></ellipse>
      </g>
    </svg>
  );
}

export default Accessory;
