import React from "react";

function FossilCategory(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210mm"
      height="297mm"
      version="1.1"
      viewBox="0 0 210 297"
      {...props}
    >
      <g>
        <path
          strokeWidth="0.265"
          d="M107.69 162.457c-.963-.186-1.73-1.382-1.577-2.46.072-.509.208-1.114.3-1.344.126-.31-.1-.525-.872-.828-2.011-.79-2.356-2.458-.783-3.782.914-.769 1.412-.812 2.823-.248l1.03.413 3.19-1.798c1.754-.99 3.158-1.83 3.12-1.869-.04-.038-1.608.055-3.486.208-2.578.21-3.965.469-5.656 1.059-3.444 1.2-4.574 1.02-6.426-1.03-.927-1.027-1.67-2.158-1.415-2.158.07 0 .537.242 1.038.538.502.296 1.535.612 2.295.702 1.354.16 1.377.152 1.078-.405-.26-.485-.538-.57-1.874-.57-1.36 0-1.687-.104-2.458-.781-1.531-1.344-1.97-4.188-.966-6.261.574-1.186 2.08-2.332 4.132-3.145.73-.29 1.658-1.06 2.734-2.272 2.972-3.344 5.133-4.345 8.978-4.162 2.793.134 4.795.99 6.225 2.66 2.142 2.502 2.755 6.525 1.39 9.134-.396.758-.72 1.622-.72 1.92 0 .298-.172.954-.382 1.457l-.382.914 1.368-.781c1.29-.737 1.364-.836 1.287-1.73-.129-1.498.518-2.245 1.946-2.245.964 0 1.257.12 1.611.661.257.392.385.986.316 1.457-.106.723-.03.821.83 1.064 1.862.524 2.565 1.628 2.104 3.3-.481 1.742-2.839 2.155-4.344.76l-.681-.63-3.358 2.3c-8.69 5.953-8.517 5.81-8.517 6.988 0 2.429-1.35 3.456-3.897 2.964zm-1.264-13.354c.17-.156.124-.328-.132-.49-.56-.354-.628-.333-1.324.407l-.624.665.909-.17c.5-.094 1.027-.28 1.171-.412zm4.74-.615c-.444-.679-.68-.794-.99-.485-.237.238.606 1.146 1.062 1.146.287 0 .273-.136-.071-.661zm4.703-.558c1.772-.891 1.776-1.17.01-.697-.989.265-1.7.638-2.017 1.06-.49.648-.49.649.14.454.346-.107 1.186-.475 1.867-.817zm-12.985-2.825c1.146-.77 1.294-1.572.37-1.993-1.061-.483-2.441-.385-3.043.217-.651.65-.67 1.118-.066 1.644.999.87 1.598.9 2.739.132zm11.16-1.343c1.813-.767 2.218-1.83 1.356-3.559-1.192-2.39-3.416-3.354-5.972-2.587-3.906 1.173-4.547 4.47-1.168 6.005 1.31.594 4.527.673 5.784.141z"
        ></path>
      </g>
    </svg>
  );
}

export default FossilCategory;
