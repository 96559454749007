import React from "react";

function Wall(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      version="1.1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g>
        <path
          strokeWidth="4.61"
          d="M113.852 570.935c-7.14-3.995-7.821-19.199-7.781-173.562l.043-169.184 11.29 18.832c22.151 36.952 70.19 58.155 108.923 48.075 11.166-2.905 64.902-19.419 119.414-36.697 54.511-17.277 103.78-32.838 109.484-34.58l10.372-3.166V324.12c0 77.505-1.455 104.676-5.8 108.282-4.38 3.636-270.48 116.335-328.658 139.193-5.216 2.05-12.974 1.753-17.287-.66zm53.873-298.552c-36.698-16.665-58.167-63.318-47.535-103.29 6.417-24.125 23.446-46.993 42.225-56.704 30.763-15.908 275.16-89.34 297.32-89.332 45.712.014 79.99 32.085 83.308 77.943 1.078 14.905-.464 34.443-3.426 43.418-7.18 21.759-34.711 45.853-62.748 54.917-155.58 50.295-261.444 81.849-274.336 81.766-8.708-.056-24.372-3.979-34.808-8.718zm61.125-34.108c13.872-12.713 17.033-19.142 18.885-38.42 3.779-39.328-17.793-67.09-52.129-67.09-20.586 0-41.407 18.287-48.759 42.825-10.626 35.467 17.784 77.953 52.127 77.953 8.534 0 19.118-5.409 29.876-15.268z"
        ></path>
      </g>
    </svg>
  );
}

export default Wall;
