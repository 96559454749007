import * as React from "react";

function ShovelIcon(props: any) {
  return (
    <svg viewBox="0 0 214.822 214.822" width="1em" height="1em" {...props}>
      <path
        d="M212.625 32.954L181.868 2.197a7.5 7.5 0 00-10.608 0c-10.236 10.238-14.309 23.895-11.556 35.002l-85.64 85.64-15.54-15.54a7.5 7.5 0 00-10.606 0l-35.574 35.574C4.384 150.834 0 161.418 0 172.675c0 11.258 4.384 21.842 12.345 29.803 7.96 7.96 18.544 12.344 29.802 12.344 11.257 0 21.841-4.384 29.802-12.344l35.574-35.573a7.5 7.5 0 000-10.608l-15.539-15.539 85.612-85.612c2.266.579 4.653.885 7.12.885 9.812 0 19.985-4.546 27.909-12.469a7.5 7.5 0 000-10.608zm-27.909 8.077c-2.313 0-5.578-.508-7.997-2.928-4.234-4.233-3.768-12.15.502-19.34l18.841 18.842c-3.7 2.199-7.663 3.426-11.346 3.426z"
      />
    </svg>
  );
}

export default ShovelIcon;

