import React from "react";

function Socks(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      version="1.1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g>
        <path
          strokeWidth="4.052"
          d="M196.523 452.251c-31.975-13.968-54.702-45.774-54.702-76.555 0-56.901 44.823-98.454 115.115-106.715l21.75-2.557-11.852-45.08c-17.654-67.15-20.457-62.543 48.837-80.286 87.652-22.443 88.576-22.528 93.852-8.705 10.677 27.975 40.63 154.598 42.81 180.97 3.073 37.176-.41 41.808-55.955 74.436-108.586 63.785-162.01 81.025-199.855 64.492zM96.86 383.93c-34.975-26.736-44.996-67.077-26.421-106.366 18.381-38.882 50.855-58.114 104.317-61.778 37.57-2.575 35.524 3.407 23.395-68.383-4.677-27.682-7.578-52.745-6.446-55.695 1.94-5.056 112.797-22.936 141.702-22.855 12.853.036 16.366 6.22 16.8 29.569l.294 15.898-48.447 11.853c-26.646 6.52-51.554 14.665-55.351 18.101-11.648 10.541-11.93 23.104-1.446 64.434 5.547 21.864 9.435 40.403 8.64 41.197-.794.795-12.068 4.212-25.053 7.594-66.022 17.197-104.563 61.486-104.733 120.353-.067 23.17-3.801 24.003-27.25 6.078z"
        ></path>
      </g>
    </svg>
  );
}

export default Socks;
