import React from "react";

function FloorAndWall(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="210mm"
      height="297mm"
      version="1.1"
      viewBox="0 0 210 297"
      {...props}
    >
      <g>
        <path
          strokeWidth="1.056"
          d="M68.753 185.302c-.726-.293-1.32-1.8-1.32-3.349.002-3.387 12.137-42.082 13.842-44.137 1.682-2.026 74.028-2.145 75.674-.125 1.525 1.871 13.967 41.558 13.967 44.55 0 1.363-.752 2.766-1.67 3.119-1.995.766-98.59.71-100.493-.058zm24.216-10.653l.334-2.903 26.047-.283 26.047-.282.7 3.186c.903 4.11 2.644 4.122 2.644.019 0-3.098.117-3.168 5.28-3.168 2.904 0 5.28-.475 5.28-1.056 0-.654-5.632-1.056-14.784-1.056-13.37 0-14.785-.177-14.798-1.848-.008-1.016-.3-2.917-.65-4.224-.637-2.375-.635-2.376 13.213-2.376 8.53 0 13.85-.405 13.85-1.056 0-.58-2.32-1.056-5.156-1.056-4.455 0-5.243-.323-5.793-2.376-1.2-4.473-.815-5.015 3.558-5.015 2.323 0 4.224-.476 4.224-1.056 0-.666-7.216-1.056-19.535-1.056-18.832 0-19.535-.076-19.535-2.112 0-1.173-.704-2.112-1.584-2.112-.88 0-1.584.939-1.584 2.112 0 1.993-.704 2.112-12.559 2.112-10.189 0-12.673.299-13.166 1.584-.484 1.262.35 1.584 4.11 1.584 4.54 0 4.72.12 4.72 3.167 0 3.136-.06 3.168-5.808 3.168-4.656 0-5.808.34-5.808 1.715 0 1.543 2.23 1.67 22.175 1.268l22.175-.448V169.106h-23.759c-15.135 0-23.759.383-23.759 1.056 0 .58 2.673 1.056 5.94 1.056h5.94l-.634 3.168c-.464 2.32-.236 3.167.854 3.167.837 0 1.633-1.268 1.821-2.903zm3.474-19.004c.4-1.596.966-3.14 1.257-3.432.291-.292 10.35-.53 22.353-.53h21.824l.331 3.431.331 3.432H95.714zm-51.537 9.589c-.388-.387-.704-14.043-.704-30.346v-29.642l2.795 3.928c3.03 4.258 9.935 8.19 14.384 8.19 2.087 0 32.555-8.774 45.914-13.222 1.015-.338 1.32 3.086 1.32 14.84v15.277H95.05c-16.131 0-15.816-.2-20.021 12.762l-2.846 8.771-12.143 5.02c-12.581 5.202-13.954 5.603-15.135 4.422zm9.054-53.144c-7.08-4.317-9.566-13.065-5.905-20.78.958-2.019 2.874-4.412 4.257-5.319 3.366-2.205 51.784-17.2 55.54-17.2 8.937 0 16.456 9.26 15.062 18.554-1.1 7.337-5.378 10.9-17.507 14.583a4423.723 4423.723 0 00-24.775 7.641c-16.456 5.13-21.598 5.615-26.672 2.521zm9.582-3.297c9.324-4.768 7.29-20.002-2.659-19.906-1.915.018-4.236.612-5.157 1.32-6.098 4.682-5.138 15.334 1.65 18.291 3.95 1.721 3.426 1.696 6.166.295z"
          display="inline"
        ></path>
      </g>
    </svg>
  );
}

export default FloorAndWall;
