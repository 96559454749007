import React from "react";

function WallMount(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      version="1.1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g>
        <path
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth="4.443"
          d="M99.23 466.105c-9.051-2.35-19.237-10.212-25.549-17.713-15.208-16.289-9.315-87.519-11.41-127.71.046-81.692 1.623-117.104 5.553-124.752 8.91-17.338 25.84-28.308 54.364-29.89 11.244-.624 25.91-1.351 25.91-1.351 8.133-7.09 18.164-15.368 36.295-29.676 19.55-15.427 36.969-28.046 38.707-28.042 1.74.004 9 5.846 16.136 12.982l12.975 12.974-20.198 15.736-20.197 15.736 70.71-.141c56.434-.113 61.214-.093 68.15.13 5.267.169-16.84-15.507-23.604-20.896l-12.299-9.8 12.297-15.411c13.977-17.517 9.066-19.26 57.978 20.57 16.158 13.157 23.152 20.734 30.302 25.702 0 0 12.97.502 19.39.502 23.976 0 45.622 8.475 57.71 23.842 9.327 11.857 9.633 15.958 9.633 129.05-1.705 42.837 5.38 112.692-11.394 130.346-6.266 7.448-18.763 15.588-27.77 18.09-20.512 5.697-343.905 5.45-363.69-.278zM448.764 317.69v-99.973h-333.24v199.945h333.24zM263.83 122.469c-18.274-7.954-28.34-23.045-28.34-42.491 0-43.817 53.881-63.947 81.932-30.61 21.24 25.241 13.163 61.965-17.943 73.29-9.807 3.57-22.818 3.966-35.649-.19z"
        ></path>
      </g>
    </svg>
  );
}

export default WallMount;
