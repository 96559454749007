import React from "react";

function Onepiece(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="170.667"
      height="170.667"
      version="1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g>
        <path
          strokeWidth="2.408"
          d="M56.698 241.623c-9.272-1.726-22.82-5.15-30.105-7.61-10.887-3.675-13.369-5.543-13.934-10.492-.378-3.312 4.77-35.226 11.44-70.92 6.67-35.695 12.128-65.939 12.128-67.208 0-1.269-1.897-.652-4.215 1.37-7.113 6.204-14.506 9.17-18.772 7.533-5.198-1.994-37.224-50.417-37.224-56.28 0-5.3 4.032-9.556 18.429-19.462C6.995 9.92 40.656-6.154 53.389-9.593c8.695-2.348 8.746-2.317 11.518 6.936 4.368 14.58 23.533 26.28 43.05 26.28 20.516 0 40.178-12.228 43.092-26.8 1.643-8.212 1.231-8.162 18.37-2.265 28.623 9.848 69.13 37.219 69.094 46.687-.012 2.891-6.531 14.47-14.49 25.729-25.328 35.835-25.684 36.048-39.734 23.712-8.357-7.337-8.606-7.399-7.286-1.806 6.155 26.08 25.104 135.03 23.99 137.932-5.437 14.17-97.167 23.585-144.295 14.811zm60.504-137.428c3.417-5.216 3.548-7.006.874-12.002-4.178-7.807-10.92-9.625-18.144-4.892-10.483 6.869-6.155 23.024 6.168 23.024 4.933 0 8.305-1.861 11.102-6.13zm-.227-39.703c3.455-3.818 3.933-6.27 2.18-11.19-3.434-9.634-9.39-12.359-17.32-7.923-7.947 4.445-9.732 13.067-4.014 19.385 5.427 5.997 13.585 5.881 19.154-.272z"
        ></path>
      </g>
    </svg>
  );
}

export default Onepiece;
