import React from "react";

function Furniture(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="170.667"
      height="170.667"
      version="1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g display="inline">
        <path
          strokeWidth="3.182"
          d="M44.75 285.655s-.39-12.446-.39-24.405v-21.744l-11.417.133h-9.546V-75.387h302.298v315.026h-7.816c-3.012 0-11.259-.157-11.259-.157.224 3.538.25 10.371.713 22.944l.86 23.354h-38.042l-4.436-23.07-4.437-23.07H91.48l-4.607 23.07-4.608 23.07-17.895-.135c-11.6-.088-12.475.03-19.62.01zM179.318 82.126c0-115.262-.607-132.057-4.773-132.057-4.166 0-4.773 16.795-4.773 132.057 0 115.262.607 132.057 4.773 132.057 4.166 0 4.773-16.795 4.773-132.057zm-32.893-32.16c1.325-3.51 2.37-8.739-1.488-14.003-10.843-14.794-31.554-.244-22.859 16.004 4.553 8.506 20.376 8.525 24.347-2zm80.689 1.81c4.808-8.985 1.012-18.58-8.273-20.911-9.473-2.378-17.248 3.406-17.248 12.83 0 14.424 18.92 20.415 25.52 8.08z"
        ></path>
      </g>
    </svg>
  );
}

export default Furniture;
