import React from "react";

function Fencing(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.32"
      height="14.08"
      version="1.1"
      viewBox="0 0 16.32 14.08"
      {...props}
    >
      <defs>
        <marker orient="auto" overflow="visible" refX="0" refY="0">
          <path
            fillOpacity="1"
            fillRule="evenodd"
            stroke="#000"
            strokeOpacity="1"
            strokeWidth=".8pt"
            d="M10 0l4-4L0 0l14 4-4-4z"
          ></path>
        </marker>
        <marker orient="auto" overflow="visible" refX="0" refY="0">
          <path
            fillOpacity="1"
            fillRule="evenodd"
            stroke="#000"
            strokeOpacity="1"
            strokeWidth=".8pt"
            d="M-10 0l-4 4L0 0l-14-4 4 4z"
          ></path>
        </marker>
      </defs>
      <g
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeMiterlimit="4"
        display="inline"
        paintOrder="normal"
      >
        <path
          fill="none"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="0.378"
          d="M1.547 7.384l-.17.006h0l-.004.16"
          opacity="1"
        ></path>
        <path
          fill="none"
          strokeLinejoin="round"
          strokeOpacity="1"
          strokeWidth="0.378"
          d="M1.373 7.55l-.065 2.709 1.24-.023.022 1.905 4.384-.161-.023-1.606h.987l.023 1.537 4.246.023-.024-1.723.585.024.064-2.797-.741-.002.047-2.525-2.272-1.446-2.204 1.423.115 2.686-.459-.046.023-2.456-2.524-1.859-2.892 1.95.092 2.204-.45.017"
          opacity="1"
        ></path>
        <path
          fillOpacity="1"
          strokeLinejoin="bevel"
          strokeWidth="0.006"
          d="M4.728 3.372c-.892.592-1.78 1.191-2.663 1.795-.096.096-.043.25-.054.371.015.623.062 1.244.062 1.866-.022.07-.13.035-.183.052-.13.016-.266-.012-.389.034-.084.096-.027.238-.048.354-.014.742-.037 1.486-.052 2.227.023.142.195.09.295.095.299.002.6-.027.897-.004.051.042.025.134.038.195.02.53.01 1.063.033 1.592.03.138.195.084.295.09 1.265-.047 2.53-.09 3.795-.14.143-.042.098-.22.107-.333.003-.411-.026-.824-.003-1.235.034-.06.134-.021.192-.037.308.002.62-.012.923.012.042.076.02.185.032.273.012.382.007.766.024 1.147.032.139.204.082.307.096 1.217.018 2.434.019 3.651.018.144-.016.107-.182.108-.283l-.016-1.374c.054-.096.175-.035.264-.049.107-.006.288.05.315-.098.042-.797.03-1.596.069-2.393-.007-.139-.166-.117-.265-.118l-.426-.006c-.089-.072-.028-.201-.045-.3.004-.737.03-1.475.033-2.21-.03-.116-.173-.144-.257-.216-.625-.396-1.247-.801-1.876-1.189-.12-.037-.211.095-.313.14-.598.39-1.206.77-1.798 1.166-.08.087-.02.22-.033.326.024.786.077 1.574.086 2.356.004.094-.112.056-.17.059-.146-.02-.3-.012-.436-.068-.024-.405-.004-.824-.005-1.235 0-.396.015-.795.006-1.19-.035-.116-.176-.153-.258-.234-.708-.517-1.406-1.05-2.128-1.548-.036-.026-.075-.012-.114-.004z"
          opacity="1"
        ></path>
      </g>
    </svg>
  );
}

export default Fencing;
