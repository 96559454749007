import React from "react";

function Hat(props:any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="128"
      version="1.1"
      viewBox="0 0 128 128"
      {...props}
    >
      <g>
        <path
          strokeWidth="2.996"
          d="M227.938 285.07C166.093 273.85 124.786 247.44 117.55 214.5c-4.76-21.675 13.873-49.322 43.288-64.227 13.525-6.854 14.853-8.418 13.448-15.833-9.625-50.773-12.044-72.714-9.02-81.805C181.36 4.25 326.838-8.15 379.646 34.36c18.573 14.952 19.054 24.836 4.856 99.85-1.319 6.968.115 8.85 11.097 14.575 32.565 16.972 49.5 40.417 45.82 63.431-5.605 35.052-47.94 62.534-112.61 73.104-31.054 5.076-72.077 4.974-100.871-.25zm92.314-50.823c37.183-7.881 59.494-24.986 59.494-45.611v-10.524l-8.942 7.524c-4.918 4.139-16.899 10.208-26.217 14.438-16.29 7.394-31.162 13.973-65.216 13.404-31.577-.713-50.52-6.26-65.216-13.404-9.203-4.475-21.299-10.3-26.217-14.438l-8.942-7.524v10.524c0 12.93 10.825 26.718 27.76 35.357 26.895 13.721 76.144 18.17 113.496 10.254z"
          display="inline"
        ></path>
      </g>
    </svg>
  );
}

export default Hat;
